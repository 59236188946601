<template>
  <div class="verify-email-page">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      class="auth-form"
      :disabled="loading"
    >
      <div class="email-icon-wrapper">
        <vsvg-icon
          data="@assetsIcon/email-icon.svg"
          scale="1"
        />
        <div
          class="notifications-count"
        >
          1
        </div>
      </div>
      <h2>Check your inbox</h2>
      <p>
        An email has been sent to <span>{{ $store.state.email }}</span>, please click the link provided to verify your address.
      </p>
      <p class="wrong-email-txt">
        Wrong email address?
      </p>
      <el-form-item
        label="Email address"
        prop="email"
      >
        <el-input
          v-model="form.email"
          type="email"
          placeholder="Enter email address"
        ></el-input>
      </el-form-item>
      <button
        id="submit"
        type="submit"
        class="fgh-button lg fgh-button--primary btn-resend"
        :disabled="loading"
        @click.prevent="onSubmit"
      >
        Resend
      </button>
    </el-form>
  </div>
</template>

<script>
import store from "@/store"
import authApi from '@/modules/auth/services/auth.api'
import authRules from '@/modules/auth/services/auth.rules'

export default {
  beforeRouteEnter(to, from, next) {
    if (store.state.mustVerify == false) {
      next({ name: 'public-home' })  
    } else {
      next()
    }
  },

  data() {
    return {
      form: {
        email :''
      },
      rules: {
        email: [
          { validator: authRules.validateEmailAddress }
        ]
      },
      loading: false
    }
  },

  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          authApi
            .resend(this.$store.state.id, this.form.email)
            .then((response) => {
              this.loading = false
              console.log(response)
              this.$store.commit('CHANGE_EMAIL', this.form.email)
              this.$notify.success({
                title: 'Success',
                message: response.data.message
              })
              this.$refs.form.resetFields()
            }).catch((error) => {
              this.loading = false
              if (error.response && error.response.status == 422) {
                this.$notify.error({
                  title: 'Error',
                  message: error.response.data.errors.email[0]
                })
              } else {
                this.$notify.error({
                  title: 'Error',
                  message: 'An unexpected error occured. Please try again later',
                })
              }
              this.$refs.form.resetFields()
            })
        }
      })
    }
  },
}
</script>

<style lang="scss">
.verify-email-page {
  .auth-form {
    max-width: 500px;
  }

  .email-icon-wrapper {
    position: relative;
    display: inline-block;
    .notifications-count {
      background: $primary-color;
      color: $white-color;
      width: 30px;
      height: 30px;
      line-height: 30px;
      position: absolute;
      right: -15px;
      top: 31px;
      border-radius: 50%;
      font-size: 18px;
    }
  }

  h2 {
    margin-top: 19px;
  }

  p {
    span {
      color: $primary-color;
    }
    &.wrong-email-txt {
      margin-top: 18px;
      font-size: 12px;
    }
  }

  .btn-resend {
    margin-top: 10px;
  }
}
</style>
